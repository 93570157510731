/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import * as tackingHttpServices from '../../services/tacking_service';
import * as directDebitServices from '../../services/direct_debit';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from "material-ui-confirm";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ModelChangeBank from '../../components/modal/Bank'
import RegisterDD from '../../components/table/regis_direct_debit'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getCommitteeSummaryList } from '../../services/committee';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,

};
const headCells = [
    {
        id: 'date_pass',
        disablePadding: false,
        width: 220,
        label: 'วันที่/เวลาที่ผ่านเกณฑ์'
    },
    {
        id: 'contract_no',
        disablePadding: false,
        width: 220,
        label: 'เลขที่สัญญา'
    },
    {
        id: 'name',
        disablePadding: false,
        width: 180,
        label: 'ชื่อ-นามสกุล',
    },
    {
        id: 'brand',
        disablePadding: false,
        width: 180,
        label: 'Brand',
    },
    {
        id: 'product',
        disablePadding: false,
        width: 180,
        label: 'Product',
    },
    {

        id: 'phone_number',
        disablePadding: false,
        width: 180,
        label: 'เบอร์โทรศัพท์',
    },
    {
        id: 'action',
        width: 80,
        label: 'Action',
    },
    {
        id: 'date_receipt',
        disablePadding: false,
        width: 200,
        label: 'วันที่แก้ไขเอกสารล่าสุด',
    },
    {
        id: 'status',
        width: 180,
        label: 'สถานะการพิจารณา',
    }, {
        id: 'form',
        width: 10,
        label: 'ดูฟอร์มสมัคร',
    },
    {
        id: 'dowloadform',
        width: 10,
        label: 'โหลดฟอร์มสมัคร',
    },
    {
        id: 'form',
        width: 20,
        label: 'ดูฟอร์มสมัคร นาโนไฟแนนซ์',
    },
    {
        id: 'form_download',
        width: 20,
        label: 'โหลดฟอร์มสมัคร นาโนไฟแนนซ์',
    },
    {
        id: '',
        width: 10,
        label: 'เพิ่มเติม',
    },

];
function MyApp() {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('collection_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows_data, setRowData] = React.useState([]);
    const [opened, setOpened] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [rowsPerFilter, setRowsPerFilter] = React.useState(1)
    const [value, setValue] = React.useState([]);
    const [tag, setTag] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const [openModelBank, setOpenModelBank] = useState(false);
    const [rows_count, setRowsCount] = React.useState(0);
    const [contract, setContarct] = React.useState(null);
    const handleClose = () => setOpen(false);
    let token = localStorage.getItem('token_org')
    const { enqueueSnackbar } = useSnackbar();
    //filter
    const [operator, setOperater] = React.useState([true, true, true, true, true, true, true])
    const [filter, setFilter] = React.useState(true)
    // console.clear();
    const confirm = useConfirm();
    const [date, setDate] = React.useState(new Date())
    const [name_filter, setNameFilter] = React.useState('');
    const [value1, setValue2] = React.useState(0);
    let org_name = localStorage.getItem('org_name')

    const handleChange = (event, newValue) => {
        setValue2(newValue);
    };
    useEffect(() => {
        setRows([])
        setStatus(false)
        setSelected([])
        setTag(false)
        let test = []
        const fetchData = async () => {
            let object = {
                "pagination": {
                    "page": page,
                    "rowsPerPage": rowsPerPage,
                    "sortBy": "",
                    "descending": true
                },
                "filters": test,
                "org_name": org_name

            }
            let res = await getData(object)
            if (res.data.data.length == 0) {
                setRows([])
                setStatus(true)
            } else {
                setStatus(true)
                prepRowData(res.data.data.rows)
                setRowsCount(res.data.data.rowcount)
            }
        }
        fetchData()
    }, [token])
    const getData = async (object) => {
        let res = await tackingHttpServices.getTackingListApproved(token, object)
        return res
    }
    const prepRowData = async (row) => {
        let return_list = []
        let committee_data = await getCommitteeSummaryList()
        console.log("committee_data", committee_data.data.data);
        for (let item of row) {
            // item
            let in_committee_summary = committee_data.data.data.documents.find(
                (cd) => cd.citizen_id === item.user_id
            );
            // console.log("in_committee_summary", in_committee_summary);
            if (in_committee_summary === undefined) {
                return_list.push({
                    'is_in_committee': false,
                    ...item
                })
            } else {
                return_list.push({
                    'is_in_committee': true,
                    ...item
                })
            }
        }
        setRows(return_list);
    }
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            checked={tag}
                            onChange={(_, tag) => {
                                setTag(tag)
                                onSelectAllClick(_, tag)
                            }}

                            inputProps={{
                                'aria-label': 'select all',
                            }}
                        // disabled
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // console.log(selected)
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            // console.log(withoutDuplicates)
            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleGetData = async (newPage = null, newRowPage = null) => {
        let object = {
            "pagination": {
                "page": newPage === null ? page : newPage,
                "rowsPerPage": newRowPage === null ? rowsPerPage : newRowPage,
                "sortBy": "",
                "descending": true
            },
            "filters": [],
            "org_name": org_name

        }
        if (newPage > page) {
            let sum = newPage == 0 ? rowsPerPage : (newPage + 1) * rowsPerPage
            if (rows.length < sum) {
                let res = await getData(object)
                if (res.data.data.length == 0) {
                    setRows([])
                    setStatus(true)
                } else {
                    setStatus(true)
                    let sum_row = rows.concat(res.data.data.rows)
                    setRows(sum_row)
                    setRowsCount(res.data.data.rowcount)
                }
            }
        }
    };
    const handleChangePage = (event, newPage) => {
        handleGetData(newPage)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const getResetFilter =  async () => {
        let object = {
            "pagination": {
                "page": 0,
                "rowsPerPage": rowsPerPage,
                "sortBy": "",
                "descending": true
            },
            "filters": [],
            "org_name": org_name

        }
        let res = await getData(object)
        if (res.data.data.length == 0) {
            setRows([])
            setStatus(true)
        } else {
            setStatus(true)
            prepRowData(res.data.data.rows)
            setRowsCount(res.data.data.rowcount)
        }
    }
    const handleChangeOperator = (index) => (e) => {
        let newOperator = []
        newOperator = [...operator]
        newOperator[index] = e.target.value
        setOperater(newOperator)
    }

    const delFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
        if (rowsPerFilter > 1) {
            setRowsPerFilter(rowsPerFilter - 1)
        }
    }

    const resetFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
    }
    const onClickFilterAction = async () => {
        const filter = name_filter.replace(/\s/g, "");

        if (name_filter != '') {
            let object = {
                "filters": filter,
                "org_name": org_name,

            }
            let committee_data = await getCommitteeSummaryList();
            let res = await getData(object)
            if (res.data.data.length == 0) {
                setRows([])
                setStatus(true)
            } else {
                setStatus(true)
                prepRowData(res.data.data.rows)
                setRowsCount(res.data.data.rowcount)
            }
        } else {
            getResetFilter()
        }
    }
    const resetFilter = (valueIndex) => {
        switch (valueIndex) {
            case 1:
                getResetFilter()
                break;
            default:
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filterGrid = (index) => {
        return (
            <>
                <Grid item >
                    <FormControl style={{ width: 300 }} >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value[index] === undefined ? '' : value[index]}
                            label="Filter"
                            onChange={valueX(index)}
                        >
                            {/* <MenuItem value={1}>เลขบัตรประชาชน</MenuItem> */}
                            <MenuItem value={1}>ชื่อ</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {filterActionGrid(index)}

                {
                    rowsPerFilter !== index + 1 ?
                        <Grid item>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                <Select
                                    style={{ width: 100 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={operator[index]}
                                    label="Operator"
                                    onChange={handleChangeOperator(index)}
                                >
                                    <MenuItem value={true}>และ</MenuItem>
                                    <MenuItem value={false}>หรือ</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        ''
                }

                {
                    value[index] !== undefined ?
                        <Grid item>
                            <Tooltip title="ล้างตัวกรอง">
                                <IconButton onClick={() => resetFilterAction(index)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :
                        ''
                }
                <Grid item marginRight={10}>
                    <Tooltip title="ลบตัวกรองข้อมูล">
                        <IconButton onClick={() => delFilterAction(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        )
    }
    const filterActionGrid = (index) => {
        if (value[index] == 1) {
            return (
                <>
                    <Grid item>
                        <TextField id="outlined-basic" label="Name" variant="outlined" value={name_filter} onChange={nameFilter} />
                    </Grid>
                </>
            )
        }
    }
    const nameFilter = (event) => {
        setNameFilter(event.target.value)
    }
    const valueX = (index) => (event) => {
        const filtered = value.filter(obj => {
            return obj === event.target.value;
        });


        if (filtered.length === 0) {

            resetFilter(value[index])

            let newValue = []
            newValue = [...value]
            newValue[index] = event.target.value
            setValue(newValue)
        } else {

        }
    };
    const displayStatus = (status) => {
        const statusText = {
            0: "ยังไม่ได้รับ",
            1: "รอตรวจสอบ",
            2: "รอการพิจารณา",
            3: "ผ่านการพิจารณา",
            4: "ไม่ผ่านการพิจารณา",
            5: "ขอ refinance",
            6: "รอ consent (หนังสือตรวจข้อมูลเครดิต)",
            7: "รอดำเนินการ"
        };

        return <div>{statusText[status]}</div>;
    }
    const routeChangeEdit = (rowdata) => {
        const parameterData = { contact_id: rowdata.contact_id, user_id: rowdata.user_id, name: rowdata.name };
        const queryParams = new URLSearchParams(parameterData).toString();
        window.open('/document_tracking_user?' + queryParams, '_blank');
    };

    const getFormDownload = (rowdata, type) => {
        let obj = {
            user_id: rowdata.user_id,
            contact_id: Number(rowdata.contact_id),
            type: type,
            brand: rowdata.brand,
        }
        tackingHttpServices.getForm(token, obj)
            .then((res) => {
                var myParam = res.data.data;
                window.open(myParam)

            })
    }

    const routeChangeForm = (rowdata, type) => {
        let obj = {
            user_id: rowdata.user_id,
            contact_id: Number(rowdata.contact_id),
            brand: rowdata.brand,
            type: type

        }
        tackingHttpServices.getForm(token, obj)
            .then((res) => {
                // console.log(res.data.data)
                var myParam = res.data.data;
                window.open('/preview?param=' + encodeURIComponent(myParam), '_blank');

            })
    };
    const onClickApprove = (row, status) => {

        if (status == 3 && open == false) {
            let obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: row.action_type,
                id: row.id,
                contract_no: row.contract_no
            }
            setContarct(row.contract_no === null ? '' : row.contract_no)
            setRowData(obj)
            setOpen(true)
        }
        else if (status == 5) {
            const obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: status,
                old_action_type: row.action_type,
                id: row.id
            }

            confirmAlert({
                title: 'ยืนยันที่จะอัพเดทสถานะ?',
                message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            tackingHttpServices.updateCollectorStatus(token, obj)
                                .then((res) => {
                                    setRowData([])
                                    window.alert('อัพเดทสถานะสำเร็จ')
                                    window.location.reload();
                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            // console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        } else if (status != 3) {
            // console.log(status)
            let obj = {
                user_id: row.user_id,
                contact_id: row.contact_id,
                action_type: row.action_type,
                id: row.id,
                contract_no: '-',
                date_approve: date,
                status: status
            }
            confirmAlert({
                title: 'ยืนยันที่จะบันทึกการอนุมัติ?',
                message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            tackingHttpServices.approveFileContract(token, obj)
                                .then((res) => {
                                    // console.log(res)
                                    window.alert('บันทึกสำเร็จ')
                                    setRowData([])
                                    window.location.reload();

                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            // console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        }
        else if (status == 3 && open == true) {
            // console.log('ma')
            let obj = {
                user_id: rows_data.user_id,
                contact_id: rows_data.contact_id,
                action_type: rows_data.action_type,
                id: rows_data.id,
                contract_no: contract,
                date_approve: date,
                status: 3
            }
            // console.log(obj)
            confirmAlert({
                title: 'ยืนยันที่จะบันทึกการอนุมัติ?',
                message: 'คุณแน่ใจหรือที่จะอัพเดทสถานะ',
                buttons: [
                    {
                        label: 'ตกลง',
                        onClick: () => {
                            tackingHttpServices.approveFileContract(token, obj)
                                .then((res) => {
                                    // console.log(res)
                                    directDebitServices.addContractDirectDebit(token, obj)
                                        .then((res_2) => {
                                            window.alert('บันทึกสำเร็จ')
                                            setRowData([])
                                            window.location.reload();
                                        })
                                })
                        }
                    },
                    {
                        label: 'ยกเลิก',
                        onClick: () => {
                            // console.log("no")
                        }
                    }
                ],
                overlayClassName: "overlay-custom-class-name"
            })
        }
    }

    const handleOpenModelBank = (row) => {
        // let obj = {
        //     row
        // }
        setContarct(row.contract_no === null ? '' : row.contract_no)
        setRowData(row)
        setOpenModelBank(true);
    };

    const handleCloseModelBank = () => {
        setOpenModelBank(false);
    };
    const handleupdateRegisterType = (row, product) => {
        let obj = {
            user_id: row.user_id,
            contact_id: row.contact_id,
            product: product,
        }
        confirmAlert({
            title: `ยืนยันที่จะเปลี่ยน Product ลูกค้าเป็น ${product}?`,
            message: 'คุณแน่ใจหรือที่จะอัพเดท',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        if (row.product === product) {
                            alert('อัพเดทสำเร็จ')
                        } else {
                            tackingHttpServices.UpdateProduct(token, obj)
                                .then((res) => {
                                    if (res.data.success) {
                                        alert('เปลี่ยน product สำเร็จ')
                                        window.location.reload();
                                    } else {
                                        alert('เปลี่ยน product ไม่สำเร็จ')
                                        window.location.reload();
                                    }
                                })
                        }
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }
    const handleupdateBrand = (row, brand) => {
        let obj = {
            user_id: row.user_id,
            contact_id: row.contact_id,
            brand: brand,
        }
        confirmAlert({
            title: `ยืนยันที่จะเปลี่ยน Brand ลูกค้าเป็น ${brand}?`,
            message: 'คุณแน่ใจหรือที่จะอัพเดท',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        if (row.brand === brand) {
                            alert('อัพเดทสำเร็จ')
                        } else {
                            tackingHttpServices.UpdateBrand(token, obj)
                                .then((res) => {
                                    if (res.data.success) {
                                        alert('เปลี่ยน Brand สำเร็จ')
                                        window.location.reload();

                                    } else {
                                        alert('เปลี่ยน Brand ไม่สำเร็จ')
                                        window.location.reload();

                                    }


                                })
                        }
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("no")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value1} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="ลูกค้าปกติ" {...a11yProps(0)} />
                    <Tab label="ลุกค้าสมัครDirect Debit" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value1} index={0}>

                {
                    rows.length === 0 && !status ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        : ""
                }
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Grid display={opened ? 'none' : ''}>
                        {
                            rowsPerFilter > 4 ?
                                <Grid container spacing={2} style={{ padding: '1rem' }}>
                                    {filterGrid(4)}
                                </Grid>
                                : null
                        }
                        {
                            rowsPerFilter > 3 ?
                                <Grid container spacing={2} style={{ padding: '1rem' }}>
                                    {filterGrid(3)}
                                </Grid>
                                : null
                        }
                        {
                            rowsPerFilter > 2 ?
                                <Grid container spacing={2} style={{ padding: '1rem' }}>
                                    {filterGrid(2)}
                                </Grid>
                                : null
                        }
                        {
                            rowsPerFilter > 1 ?
                                <Grid container spacing={2} style={{ padding: '1rem' }}>
                                    {filterGrid(1)}
                                </Grid>
                                : null
                        }
                        <Grid container spacing={1} style={{ padding: '1rem' }}>
                            {filterGrid(0)}
                            <Grid item >
                                <Button variant="contained" onClick={() => onClickFilterAction()}>ค้นหา</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <TableContainer sx={{ maxHeight: 700 }}>
                        <Table
                            // sx={{ minWidth: 750 }}
                            // aria-labelledby="tableTitle"
                            // size='medium'
                            stickyHeader aria-label="sticky table"
                        >
                            {/* <Table stickyHeader aria-label="sticky table"> */}
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                {/* {console.log(row.status, row.approve_date)} */}
                                                <TableCell style={{ textAlign: 'center' }}>{row.status == 4 || row.status == 2 || row.status == 5 || row.status == 7 ? "-" : row.approve_date == null ? "ไม่ได้กรอกข้อมูล" : row.approve_date.split("T")[0]}</TableCell>
                                                <TableCell >{row.contract_no == null ? "-" : row.contract_no}</TableCell>

                                                {/* <TableCell >{"-"}</TableCell> */}
                                                <TableCell>{row.name + " " + row.surname}</TableCell>
                                                <TableCell style={{ textAlign: 'center', width: 250 }}>
                                                    <Select
                                                        value={row.brand}
                                                        // onChange={handleupdateBrand}
                                                        size="small"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        style={{ width: 250 }}
                                                        disabled={row.status === 3 ? true : false || org_name=='NVCASH'}
                                                    >
                                                        <MenuItem value="CASHKRUB" onClick={() => handleupdateBrand(row, 'CASHKRUB')}>CASHKRUB</MenuItem>
                                                        <MenuItem value="MSCW" onClick={() => handleupdateBrand(row, 'MSCW')}>MSCW</MenuItem>
                                                        <MenuItem value="PICO TH" onClick={() => handleupdateBrand(row, 'PICO TH')}>PICO TH</MenuItem>
                                                        <MenuItem value="NVCASH" onClick={() => handleupdateBrand(row, 'NVCASH')}>NV Cash</MenuItem>
                                                        <MenuItem value="EASY-CAPITAL" onClick={() => handleupdateBrand(row, 'EASY-CAPITAL')}>Easy Capital</MenuItem>
                                                        <MenuItem disabled value="MOU-GLOBALSERVICE" onClick={() => handleupdateBrand(row, 'MOU-GLOBALSERVICE')}>MOU-GLOBALSERVICE</MenuItem>
                                                        <MenuItem disabled value="NTK-Dauto" onClick={() => handleupdateBrand(row, 'NTK-Dauto')}>NTK-D auto</MenuItem>
                                                        <MenuItem disabled value="ALIX" onClick={() => handleupdateBrand(row, 'ALIX')}>ALIX</MenuItem>
                                                        <MenuItem disabled value="TARNA" onClick={() => handleupdateBrand(row, 'TARNA')}>TARNA</MenuItem>
                                                        <MenuItem disabled value="MALIBU" onClick={() => handleupdateBrand(row, 'MALIBU')}>MALIBU</MenuItem>
                                                        <MenuItem disabled value="SHASA" onClick={() => handleupdateBrand(row, 'SHASA')}>SHASA</MenuItem>
                                                        <MenuItem disabled value="WISHMOTORS" onClick={() => handleupdateBrand(row, 'WISHMOTORS')}>WISHMOTORS</MenuItem>
                                                        <MenuItem disabled value="MOU-SCAN" onClick={() => handleupdateBrand(row, 'MOU-SCAN')}>MOU-SCAN</MenuItem>
                                                        <MenuItem disabled value="MOU-THAI-JAPAN" onClick={() => handleupdateBrand(row, 'MOU-THAI-JAPAN')}>MOU-THAI-JAPAN</MenuItem>
                                                        <MenuItem disabled value="MOU-SIBA" onClick={() => handleupdateBrand(row, 'MOU-SIBA')}>MOU-SIBA</MenuItem>
                                                        <MenuItem disabled value="MOU-XINGHUA" onClick={() => handleupdateBrand(row, 'MOU-XINGHUA')}>MOU-XINGHUA</MenuItem>
                                                        <MenuItem disabled value="MOU-AKS" onClick={() => handleupdateBrand(row, 'MOU-AKS')}>MOU-AKS</MenuItem>
                                                    </Select>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', width: 250 }}>
                                                    <Select
                                                        value={row.product}
                                                        // onChange={handleupdateBrand}
                                                        size="small"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        style={{ width: 250 }}
                                                        disabled={row.status === 3 || row.is_in_committee ? true : false}
                                                    // disabled={row.is_in_committee}
                                                    >
                                                        <MenuItem
                                                            value="PLOAN"
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN')
                                                            }
                                                        >
                                                            PLOAN
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="PLOAN-NONCB"
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-NONCB')
                                                            }
                                                        >
                                                            PLOAN ไม่นับคะแนนเครดิต
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="PLOAN-ALL"
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-ALL')
                                                            }
                                                        >
                                                            สินเชื่อรวมหนี้
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="PLOAN-MOU"
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-MOU')
                                                            }
                                                        >
                                                            สินเชื่อ MOU
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="NANO"
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'NANO')
                                                            }
                                                        >
                                                            สินเชื่อนาโนไฟแนนซ์
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="PLOAN-PAOTANG"
                                                            onClick={
                                                                () => handleupdateRegisterType(row, 'PLOAN-PAOTANG')
                                                            }
                                                        >
                                                            PLOAN เป๋าตัง
                                                        </MenuItem>
                                                    </Select>

                                                </TableCell>
                                                <TableCell>{row.phone_number}</TableCell>
                                                {row.status == 4 || row.status == 3 ? ' ' : <TableCell > <Select
                                                    // value={row.action_type}
                                                    // onChange={handleChange}
                                                    size="small"
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ width: 150 }}
                                                >
                                                    <MenuItem><em>Action</em></MenuItem>
                                                    <MenuItem value={2} onClick={() => onClickApprove(row, 2)}>รอการพิจารณา</MenuItem>
                                                    <MenuItem value={6} onClick={() => onClickApprove(row, 6)}>รอconsent(หนังสือตรวจข้อมูลเครดิต)</MenuItem>
                                                    <MenuItem value={3} onClick={() => onClickApprove(row, 3)}>ผ่านการพิจารณา</MenuItem>
                                                    <MenuItem value={4} onClick={() => onClickApprove(row, 4)}>ไม่ผ่านการพิจารณา</MenuItem>
                                                    <MenuItem value={5} onClick={() => onClickApprove(row, 5)}>ขอ refinance</MenuItem>
                                                    <MenuItem value={0} onClick={() => handleOpenModelBank(row)}>เลือก / เปลี่ยน ธนาคาร</MenuItem>
                                                </Select></TableCell>}
                                                {row.status !== 3 ? ' ' : <TableCell > <Select
                                                    // value={row.action_type}
                                                    // onChange={handleChange}
                                                    size="small"
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    style={{ width: 150 }}
                                                >
                                                    <MenuItem><em>Action</em></MenuItem>
                                                    <MenuItem value={3} onClick={() => onClickApprove(row, 3)}>บันทึกเลขที่สัญญา</MenuItem>
                                                    <MenuItem value={5} onClick={() => onClickApprove(row, 5)}>ขอ refinance</MenuItem>
                                                    <MenuItem value={0} onClick={() => handleOpenModelBank(row)}>เลือก / เปลี่ยน ธนาคาร</MenuItem>
                                                </Select></TableCell>}
                                                <TableCell>{row.file_update_date == null ? "-" : row.file_update_date.split("T")[0]}</TableCell>
                                                <TableCell>{displayStatus(row.status)}</TableCell>
                                                <TableCell><IconButton onClick={(e) => routeChangeForm(row, 'MSCW')}><SearchIcon color="primary" /></IconButton></TableCell>
                                                <TableCell><IconButton onClick={(e) => getFormDownload(row, 'MSCW')}><SaveAltIcon color="primary" /></IconButton></TableCell>
                                                <TableCell><IconButton onClick={(e) => routeChangeForm(row, 'NANO')}><SearchIcon color="primary" /></IconButton></TableCell>
                                                <TableCell><IconButton onClick={(e) => getFormDownload(row, 'NANO')}><SaveAltIcon color="primary" /></IconButton></TableCell>
                                                <TableCell><IconButton onClick={(e) => routeChangeEdit(row)}><SearchIcon color="primary" /></IconButton></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                        component="div"
                        count={rows_count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ zIndex: 2 }}
                >
                    <Box sx={style}>
                        <IconButton
                            style={{ position: "absolute", top: "0", right: "0" }}
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                        {/* {console.log(file_type)} */}
                        <h2 id="app">ยืนยันการผ่านการอนุมัติ</h2>

                        <TextField style={{ marginBottom: '2rem', width: 230 }} id="outlined-basic2" label="เลขที่สัญญา" variant="outlined" size="small" value={contract} onChange={(e) => setContarct(e.target.value)} />
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DesktopDatePicker
                                label="วันที่ผ่านการอนุมัติ"
                                inputFormat="YYYY-MM-DD"
                                value={date}
                                onChange={(date) => setDate(date)}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <br></br>
                        <Button style={{ float: 'right', marginTop: 10 }} variant="contained" onClick={() => onClickApprove('', 3)}>ยืนยัน</Button>
                    </Box>

                </Modal>

                <ModelChangeBank
                    open={openModelBank}
                    close={handleCloseModelBank}
                    data={rows_data}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value1} index={1}>
                <RegisterDD />
            </CustomTabPanel>
        </Box>
    );
}
export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <MyApp />
        </SnackbarProvider>
    );
}

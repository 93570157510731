/* eslint-disable no-unused-vars */

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectDebit from '../../services/direct_debit.js';
import * as LoginService from '../../services/login_service.js';
// components
import MyComponent from '../../components/filter.js';
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function App() {
    let token = localStorage.getItem('token')
    let decoded
    let org_name = localStorage.getItem('org_name')

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_tran, setRowsTran] = React.useState(true);
    const [start_date, setStartDate] = React.useState(null);
    const [end_date, setEndDate] = React.useState(null)
    const location = useLocation();
    const has_permis = checkPermission(location.pathname, decoded);
    React.useEffect(() => {
        if (!has_permis) {
            window.location.href = '/404';
        }
        loadAwaitList()

    }, [])

    const loadAwaitList = async () => {
        setOpen(true);
        let obj = {
            status: 1,
            start_date: start_date,
            end_date: end_date
        }
        try {

            let res = await DirectDebit.getTransactionStatus(obj)
            let user_brand = await LoginService.getAllBrandUser(obj)
            const mergedArr = res.data.data.reduce((acc, obj1) => {
                const obj2 = user_brand.data.data.find(obj2 => String(obj2.contact_id) === obj1.contract_id)
                return [...acc, { ...obj1, ...obj2 }];
            }, []);
            let filteredArray = []
            if (org_name === "NVCASH") {
                filteredArray = mergedArr.filter(item => item.contact_brand === "NVCASH");
            } else {
                filteredArray = res.data.data
            }
            setRowsTran(filteredArray)
        } catch (error) {
            setRowsTran([])
        }
    }
    const handleSearchButton = () => {

        if (!start_date || !end_date || end_date < start_date) {
            alert("วันที่ผิดโปรดเลือกวันที่ใหม่อีกครั้ง");
            return;
        }
        else {

            loadAwaitList();
        }
    }
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'No',
            headerName: 'No.',
            width: 100,
            headerAlign: 'center',
            align: 'center',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,

        },
        {
            field: 'transaction_date',
            headerName: 'วันที่ชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : params.row.transaction_date.split("T")[0]}`,
        },
        {
            field: 'col3',
            headerName: 'เวลาที่ชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.transaction_date == null ? "-" : new Date(params.row.transaction_date).toLocaleTimeString("th-TH", { timeZone: "UTC" })}`,
        },
        {
            field: 'name',
            headerName: 'ชื่อผู้ชำระ',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'referenceNo',
            headerName: 'รหัสอ้างอิง1',
            width: 200,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'amount',
            headerName: 'จำนวนเงิน',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => `${params.row.amount - params.row.vat}`,
        },

    ];
    return (
        <>
            {has_permis && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Pay Success Page</Typography>
                        </Grid>
                    </Grid>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <Typography variant='h6' style={{ padding: '10px' }}>Success payment list</Typography>
                        <Grid container spacing={2} style={{ padding: '1rem' }}>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="วันที่เริ่มต้น"
                                        inputFormat="YYYY-MM-DD"
                                        value={start_date}
                                        onChange={(date) => setStartDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="ถึงวันที่"
                                        inputFormat='YYYY-MM-DD'
                                        value={end_date}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) => <TextField size="small" {...params} />}
                                        minDate={start_date}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' size='small'
                                    onClick={() => handleSearchButton()}
                                >
                                    ค้นหา
                                </Button>
                            </Grid>
                            <Grid item>
                                {/* <Button variant="outlined" size='small'
                                    onClick={() => handleClearButton()}
                                >
                                    เคลียร์
                                </Button> */}
                            </Grid>

                        </Grid>
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_tran}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )}
        </>
    )
}
import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ThaiFontPath from '../assets/fonts/THSarabunNew.ttf';
import ThaiFontBoldPath from '../assets/fonts/THSarabunNew Bold.ttf';
import headerImage from '../img/report_header.jpg'

// ...

export function generatePdfDocument(data, date) {
  const doc = new jsPDF('landscape');

  // Load the fonts
  doc.addFont(ThaiFontPath, 'THSarabunNew', 'normal');
  doc.addFont(ThaiFontBoldPath, 'THSarabunNew-Bold', 'bold');

  // Set font for the whole document
  doc.setFont('THSarabunNew');

  // Header
  doc.addImage(headerImage, 'JPG', 10, 1, 280, 30);

  //const currentDate = new Date();


  const transferDate = new Date(date);
  const formattedDate = `${transferDate.getDate()}-${(transferDate.getMonth() + 1).toString().padStart(2, '0')}-${transferDate.getFullYear()}`;
  const transfer_Date = `${transferDate.getDate()}-${(transferDate.getMonth() + 1).toString().padStart(2, '0')}-${transferDate.getFullYear()}`;

  const dateTable = [
    [{ content: 'วันที่จัดทำ', colSpan: 1, styles: { halign: 'center' } }, formattedDate],
    [{ content: 'วันที่โอน', colSpan: 1, styles: { halign: 'center' } }, transfer_Date],
  ];
  const dateTableColumnStyles = {
    0: { cellWidth: 50 },
    1: { cellWidth: 50 },
  };
  doc.autoTable({
    body: dateTable,
    startY: 35,
    theme: 'grid', // Use 'plain' theme for no borders
    columnStyles: dateTableColumnStyles,
    styles: {
      lineColor: [0, 0, 0],
      font: 'THSarabunNew-Bold',
    }
  });


  const center_txt = 'รายละเอียดการปล่อยสินเชื่อประจำวัน'
  const policygroup = Array.from(new Set(data.map(item => item.policygroup)))

  const policygroup_list = policygroup.join(',')


  doc.setFontSize(13)


  doc.text(center_txt, (doc.internal.pageSize.getWidth() / 2) + 20, 40, { align: 'center' })
  doc.text(policygroup_list, (doc.internal.pageSize.getWidth() / 2) + 20, 45, { align: 'center' })

  const getNextMonth = (event) => {
    const d = new Date(data[0].d_loan);
    const day = d.getDate();
    let month = d.getMonth();
    if (day > 16) {
      month = (month + event + 1) % 12;
    } else {
      month = (month + event) % 12;
    }
    return getMonthName(month);
  }
  const getMonthName = (monthIndex) => {
    const monthNames = [
      'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม ', 'มิถุนายน', 'กรกฎาคม',
      'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
    ];
    return monthNames[monthIndex];
  };
  // Create the table header
  const header = [
    [
      { content: 'ชื่อ-นามสกุล', colSpan: 2, styles: { halign: 'center' } },
      { content: 'ยอดอนุมัติ', colSpan: 1, styles: { halign: 'center' } },
      { content: 'ค่าอากร', colSpan: 1, styles: { halign: 'center' } },
      { content: 'ค่าตรวจบูโร', colSpan: 1, styles: { halign: 'center' } },
      { content: 'ค่าธรรมเนียม', colSpan: 1, styles: { halign: 'center' } },
      { content: 'ค่างวดล่วงหน้า \nเดือน \n' + getNextMonth(0), colSpan: 1, styles: { halign: 'center' } },
      { content: 'ค่างวดล่วงหน้า \nเดือน \n' + getNextMonth(1), colSpan: 1, styles: { halign: 'center' } },
      { content: 'ยอดชำระเพื่อปิดบัญชีเดิม', colSpan: 1, styles: { halign: 'center' } },
      { content: 'ยอดโอนสุทธิ (บาท)', colSpan: 1, styles: { halign: 'center' } },
      { content: 'ธนาคาร/เลขที่บัญชี', colSpan: 2, styles: { halign: 'center' } },
      { content: 'รายชื่อตัวแทน', colSpan: 1, styles: { halign: 'center' } },
      { content: 'เข้าร่วม Direct Debit', colSpan: 1, styles: { halign: 'center' } },
      { content: 'หมายเหตุ', colSpan: 1, styles: { halign: 'center' } },
    ],
  ];
  const columnStyles = {
    0: { cellWidth: 8 }, // no
    1: { cellWidth: 30 },  // fullname
    2: { cellWidth: 20 }, //offerlimit
    3: { cellWidth: 12 }, // dutyfee
    4: { cellWidth: 12 }, // bereau
    5: { cellWidth: 12 }, //fee
    6: { cellWidth: 20 }, // advancepaid_month1
    7: { cellWidth: 20 }, // advancepaid_month2
    8: { cellWidth: 20 }, // close_payment
    9: { cellWidth: 20 }, // net_transfer
    10: { cellWidth: 30 }, // bank_name
    11: { cellWidth: 20 }, // account_no
    12: { cellWidth: 20 }, // case
    13: { cellWidth: 15 }, // direct_debit
    14: { cellWidth: 20 } // remark
  };

  const totals = {
    fullname: 'รวม',
    offer_limit: data.reduce((sum, row) => sum + (parseFloat(row.offer_limit.replace(/,/g, '')) || 0), 0).toLocaleString(),
    duty_fee: data.reduce((sum, row) => sum + (parseFloat(row.duty_fee) || 0), 0).toFixed(0),
    bureau: data.reduce((sum, row) => sum + (parseFloat(row.bureau) || 0), 0),
    fee: data.reduce((sum, row) => sum + (parseFloat(row.fee) || 0), 0),
    advancepaid_month1: data.reduce((sum, row) => sum + (parseFloat(row.advancepaid_month1.replace(/,/g, '')) || 0), 0).toLocaleString(),
    advancepaid_month2: data.reduce((sum, row) => sum + (parseFloat(row.advancepaid_month2.replace(/,/g, '')) || 0), 0).toLocaleString(),
    close_payment: data.reduce((sum, row) => sum + (parseFloat(row.close_payment.replace(/,/g, '')) || 0), 0).toLocaleString(),
    net_transfer: data.reduce((sum, row) => sum + (parseFloat(row.net_transfer.replace(/,/g, '')) || 0), 0).toLocaleString()
  };


  // Table header and rows using autoTable
  doc.autoTable({
    head: header,
    body: data.map((row, index) => [
      index + 1,
      row.fullname,
      row.offer_limit,
      row.duty_fee,
      row.bureau,
      row.fee,
      row.advancepaid_month1,
      row.advancepaid_month2,
      row.close_payment,
      row.net_transfer,
      row.bank_name,
      row.account_no,
      row.case_maker,
      row.direct_debit,
      row.old_policy_group
    ]),

    startY: doc.previousAutoTable.finalY + 10, //Adjust startY based on the header's height
    theme: 'grid',
    styles: {
      font: 'THSarabunNew-Bold',
      fontSize: 10,
      lineColor: [0, 0, 0],
    },
    headStyles: {
      fillColor: [255, 255, 255], // Set background color to white
      textColor: [0, 0, 0],
      fontStyle: 'bold',
      lineColor: [0, 0, 0], // Set border color to black
      lineWidth: 0.1, // Adjust the line width as needed
    },
    columnStyles: columnStyles,
  });


  const columnTotalStyles = {
    0: { cellWidth: 38 },  // fullname
    1: { cellWidth: 20 }, //offerlimit
    2: { cellWidth: 12 }, // dutyfee
    3: { cellWidth: 12 }, // bereau
    4: { cellWidth: 12 }, // fee
    5: { cellWidth: 20 }, // advancepaid_month1
    6: { cellWidth: 20 }, // advancepaid_month2
    7: { cellWidth: 20 }, // close_payment
    8: { cellWidth: 20 }, // net_transfer

  };

  const totalsTable = [
    ['รวม', totals.offer_limit, totals.duty_fee, totals.bureau, totals.fee, totals.advancepaid_month1, totals.advancepaid_month2, totals.close_payment, totals.net_transfer],
  ];
  doc.autoTable({
    body: totalsTable,
    startY: doc.previousAutoTable.finalY + 10,
    theme: 'grid',
    styles: {
      font: 'THSarabunNew-Bold',
      fontSize: 10,
      lineColor: [0, 0, 0],
    },
    columnStyles: columnTotalStyles,
  });

  // Save the PDF
  doc.save('pdf-document.pdf');
}



import * as React from 'react';
import * as userHttpServices from './../../../src/services/services';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import jwt_decode from "jwt-decode";
const headCells = [
    {
        id: 'ActionOnDate',
        disablePadding: false,
        width: 130,
        label: 'รอบการทวง',
    },
    {
        id: 'Policy_no',
        disablePadding: false,
        width: 130,
        label: 'เลขที่สัญญา',
    },
    {
        id: 'Ku_name',
        disablePadding: false,
        width: 200,
        label: 'ผู้กู้'
    },
    {
        id: 'ProdName',
        disablePadding: false,
        width: 180,
        label: 'ประเภทลูกหนี้',
    },
    {
        id: 'PolicyGroup',
        disablePadding: false,
        width: 180,
        label: 'กลุ่มสัญญา',
    },
    {
        // id: 'TodayBalance',
        id: 'OSB',
        disablePadding: false,
        width: 100,
        label: 'เงินต้นคงเหลือ',
    },
    {
        id: 'PPP',
        disablePadding: false,
        width: 100,
        label: 'ยอดผ่อน',
    },
    {
        // id: 'InterestRemaining',
        id: 'CUMINT',
        disablePadding: false,
        width: 100,
        label: 'ดอกเบี้ยคงค้าง',
    },
    {
        id: 'Interest_rate',
        width: 100,
        label: 'อัตราดอกเบี้ย',
    },
    {
        id: 'PaymentRemaining',
        width: 100,
        label: 'งวดคงเหลือ',
    },
    {
        id: 'Budget2',
        width: 100,
        label: 'Bucket',
    },
    {
        id: 'last_pay',
        width: 150,
        label: 'วันที่จ่ายล่าสุด',
    },
    {
        id: 'remark_pay',
        width: 150,
        label: 'รายการพักเงิน',
    },
    {
        id: 'Name',
        width: 100,
        label: 'Collector',
    },
    {
        id: '',
        width: 10,
        label: '',
    },

];
function MyApp() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [users, setUsers] = React.useState([]);
    const [AssignUserID, setAssignUserID] = React.useState('');
    const [opened, setOpened] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [status, setStatus] = React.useState(false)
    const [rowsPerFilter, setRowsPerFilter] = React.useState(1)
    const [value, setValue] = React.useState([]);
    const [tag, setTag] = React.useState(false)
    let org_name = localStorage.getItem('org_name')

    const amount = [0, 0, 25, 0, 343, 742, 0, 0, 0, 0, 0, 0, 0, 0, 0, 134, 420, 193]
    const hantest7 = (event) => {
        const {
            target: { value },
        } = event;
        settest7(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    let token = localStorage.getItem('token_org')

    const { enqueueSnackbar } = useSnackbar();

    //filter
    const [operator, setOperater] = React.useState([true, true, true, true, true, true, true])
    const [filter, setFilter] = React.useState(true)
    // console.clear();

    const [date, setDate] = React.useState(null)
    const [test2, settest2] = React.useState('');
    const [test3, settest3] = React.useState('');
    const [test4, settest4] = React.useState('');
    const [test5, settest5] = React.useState('');
    const [test6, settest6] = React.useState('');
    const [test7, settest7] = React.useState([]);

    const [filterPolicyGroup, setFilterPolicyGroup] = React.useState('')
    const [filterProdName, setFilterProdName] = React.useState('')
    const [filterRemarkPay, setFilterRemarkPay] = React.useState('')

    React.useEffect(() => {
        setRows([])
        setStatus(false)
        setSelected([])
        setTag(false)

        let test = []
        value.map((val, index) => {
            let operate = operator[index - 1] ? 'and' : 'or'
            // console.log('operate111', operate)

            if (index === 0) { operate = 'and' }

            switch (val) {
                case 1:
                    test.push({
                        "operator": operate,
                        "attribute": 'ActionOnDate',
                        "search": date.format('YYYY-MM-DD')
                    })
                    break;
                case 2:
                    test.push({
                        "operator": operate,
                        "attribute": 'Policy_no',
                        "search": test2
                    })
                    break;
                case 3:
                    test.push({
                        "operator": operate,
                        "attribute": 'Ku_name',
                        "search": test3
                    })
                    break;
                case 4:
                    test.push({
                        "operator": operate,
                        "attribute": 'ProdName',
                        "search": filterProdName === '' ? test4 : filterProdName

                    })
                    break;
                case 5:
                    test.push({
                        "operator": operate,
                        "attribute": 'PolicyGroup',
                        "search": filterPolicyGroup === '' ? test5 : filterPolicyGroup

                    })
                    break;
                case 6:
                    test.push({
                        "operator": operate,
                        "attribute": 'remark_pay',
                        "search": filterRemarkPay === '' ? test6 : filterRemarkPay
                    })
                    break;
                case 7:
                    if (test7.length === 0) {
                        test.push({
                            "operator": operate,
                            "attribute": 'Name',
                            "search": ""
                        })
                    } else {
                        test7.map((value, index2) => {
                            if (index2 > 0) { operate = 'or' }
                            test.push({
                                "operator": operate,
                                "attribute": 'Name',
                                "search": value
                            })

                        })
                    }
                    break;
                default:
                // code block
            }
        })

        let object = {
            "pagination": {
                "page": 1,
                "rowsPerPage": 10000,
                "sortBy": "",
                "descending": true
            },
            "filters": test
        }

        console.log('filter', object)

        userHttpServices.getManagement(token, object)
            .then((res) => {
                setStatus(true)
                if(org_name ==="NVCASH"){
                    const filteredArray = res.data.data.filter(item => item.PolicyGroup === "ฝากบันทึก - Nova Capital");
                    setRows(filteredArray)
                }else{
                    setRows(res.data.data)
                }    
            })

        userHttpServices.getUserCollector(token)
            .then((res) => {
                // setUsers(res.data.data)

                const tester = (res.data.data).map((c, i) => {
                    const obj = c
                    Object.assign(obj, { task: amount[i] })
                    return obj
                })
                // console.log(tester)
                // alert( amount.sort( (a, b) => a. > b ? 1 : -1) )
                const numAscending = [...tester].sort((a, b) => a.task - b.task);
                // console.log(numAscending);

                setUsers(numAscending)
            })
    }, [filter])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            // checked={rowCount > 0 && numSelected === rowCount}
                            // onChange={onSelectAllClick}
                            checked={tag}
                            onChange={(_, tag) => {
                                setTag(tag)
                                onSelectAllClick(_, tag)
                            }}

                            inputProps={{
                                'aria-label': 'select all',
                            }}
                        // disabled
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align='left'
                            padding='normal'
                            width={headCell.width}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };


    const handleChange = (event) => {
        setAssignUserID(event.target.value)
    };

    const assign = () => {
        // console.log(selected)
        selected.map((id, index) => {
            userHttpServices.assignCollector(token, id, AssignUserID)
                .then(() => {
                    if (index === selected.length - 1) {
                        enqueueSnackbar('Assign collector success!', { variant: 'success' });

                        setFilter(current => !current)
                        setSelected([])

                        // window.location.reload()
                    }
                })
        })
    }

    const EnhancedTableToolbar = (props) => {
        const { numSelected } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.text.primary, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} items selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        รายการทั้งหมด
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <>
                        {/* <Button color="inherit" variant="subtitle1" sx={{ flex: '1 1 20%' }} onClick={() => assign()}><AddIcon />{' Assign Collector'}</Button> */}
                        <Button variant="contained" color="success" style={{ marginRight: '1rem', width: '18rem' }} onClick={() => assign()}><AddIcon /> Assign Collector</Button>
                        {/* <FormControl sx={{ m: 1}}> */}
                        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                            <InputLabel>assign to</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={AssignUserID}
                                label="assign to"
                                onChange={handleChange}
                            >
                                {
                                    users.map((user, index) => {
                                        if (user.active) {
                                            // return <MenuItem key={index} value={user.ID} style={{ backgroundColor: color(user.task) }}>{user.Name} ({user.task})</MenuItem>
                                            return <MenuItem key={index} value={user.ID}>{user.Name}</MenuItem>
                                        }
                                    })
                                }
                            </Select>
                            {/* <FormHelperText>With label + helper text</FormHelperText> */}
                        </FormControl>
                    </>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton onClick={() => tabFilter()}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // console.log(selected)
            const newSelecteds = stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => n.id);

            let withoutDuplicates = [...new Set(selected.concat(newSelecteds))];

            // console.log(withoutDuplicates)
            setSelected(withoutDuplicates);

            return;
        }

        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const onClickIcon = (id) => {
        window.open("/contact_detail/" + id)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const tabFilter = () => {
        setOpened(current => !current)
    }

    const addFilter = () => {
        if (rowsPerFilter < 5) {
            setRowsPerFilter(rowsPerFilter + 1)
        }
    }

    const handleChangeOperator = (index) => (e) => {
        let newOperator = []
        newOperator = [...operator]
        newOperator[index] = e.target.value
        setOperater(newOperator)
    }

    const delFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
        if (rowsPerFilter > 1) {
            setRowsPerFilter(rowsPerFilter - 1)
        }
    }

    const resetFilterAction = (index) => {
        resetFilter(value[index])

        delete value[index]
        let arrFiltered = value.filter(Boolean);
        setValue(arrFiltered)
    }

    const resetFilter = (valueIndex) => {
        switch (valueIndex) {
            case 1:
                setDate(null)
                break;
            case 2:
                settest2('')
                break;
            case 3:
                settest3('')
                break;
            case 4:
                settest4('')
                break;
            case 5:
                settest5('')
                break;
            case 6:
                settest6('')
                break;
            case 7:
                settest7([])
                break;
            default:
            // code block
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filterGrid = (index) => {
        return (
            <>
                <Grid item >
                    <FormControl style={{ width: 300 }} >
                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value[index] === undefined ? '' : value[index]}
                            label="Filter"
                            onChange={valueX(index)}
                        >
                            <MenuItem value={1}>รอบการทวง</MenuItem>
                            <MenuItem value={2}>เลขที่สัญญา</MenuItem>
                            <MenuItem value={3}>ผู้กู้</MenuItem>
                            <MenuItem value={4}>ประเภทลูกหนี้</MenuItem>
                            {org_name === "NVCASH" ? <></> : <MenuItem value={5}>กลุ่มสัญญา</MenuItem>}
                            <MenuItem value={6}>รายการพักเงิน</MenuItem>
                            <MenuItem value={7}>Collector</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {filterActionGrid(index)}

                {
                    rowsPerFilter !== index + 1 ?
                        <Grid item>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                                <Select
                                    style={{ width: 100 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={operator[index]}
                                    label="Operator"
                                    onChange={handleChangeOperator(index)}
                                >
                                    <MenuItem value={true}>และ</MenuItem>
                                    <MenuItem value={false}>หรือ</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        ''
                }

                {
                    value[index] !== undefined ?
                        <Grid item>
                            <Tooltip title="ล้างตัวกรอง">
                                <IconButton onClick={() => resetFilterAction(index)}>
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :
                        ''
                }

                <Grid item marginRight={10}>
                    <Tooltip title="ลบตัวกรองข้อมูล">
                        <IconButton onClick={() => delFilterAction(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </>
        )
    }

    const valueX = (index) => (event) => {
        const filtered = value.filter(obj => {
            return obj === event.target.value;
        });


        if (filtered.length === 0) {

            resetFilter(value[index])

            let newValue = []
            newValue = [...value]
            newValue[index] = event.target.value
            setValue(newValue)
        } else {

        }
    };

    const onClickFilterAction = () => {
        setFilter(current => !current)
    }

    //filter 
    const handleChangeDate = (index) => (newDate) => {
        setDate(newDate)
    }

    const hantest2 = (event) => {
        settest2(event.target.value)
    }

    const hantest3 = (event) => {
        settest3(event.target.value)
    }

    const hantest4 = (event) => {
        settest4(event.target.value)
    }

    const handleFilterProdName = (event) => {
        setFilterProdName(event.target.value)
    }

    const hantest5 = (event) => {
        settest5(event.target.value)
    }

    const handleFilterPolicyGroup = (event) => {
        setFilterPolicyGroup(event.target.value)
    }

    const hantest6 = (event) => {
        settest6(event.target.value)
    }

    const handleFilterRemarkPay = (event) => {
        setFilterRemarkPay(event.target.value)
    }
    // const hantest7 = (event) => {
    //     console.log('>>>>', event)
    //     settest7(event.target.value)
    // }

    // search
    const filterActionGrid = (index) => {
        if (value[index] == 1) {
            return (
                <>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <MobileDatePicker
                                label="Date filter"
                                inputFormat="YYYY-MM-DD"
                                value={date}
                                onChange={handleChangeDate(index)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </>
            )
        } else if (value[index] == 2) {
            return (
                <>
                    <Grid item>
                        {/* <TextField id="outlined-basic" label="Name" variant="outlined" value={test1} onChange={handleChangeFilters(index)} /> */}
                        <TextField id="outlined-basic" label="Contract number" variant="outlined" value={test2} onChange={hantest2} />
                    </Grid>
                </>
            )
        } else if (value[index] == 3) {
            return (
                <>
                    <Grid item>
                        <TextField id="outlined-basic" label="Name" variant="outlined" value={test3} onChange={hantest3} />
                    </Grid>
                </>
            )
        } else if (value[index] == 4) {
            return (
                <>
                    <Grid item >
                        <FormControl style={{ width: 195 }} >
                            <InputLabel id="demo-simple-select-label">Optional</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={test4}
                                label="Optional"
                                onChange={hantest4}
                            >
                                <MenuItem value={'สินเชื่อรายย่อยชั้นดี'}>สินเชื่อรายย่อยชั้นดี</MenuItem>
                                <MenuItem value={'สินเชื่อรายย่อยทั่วไป'}>สินเชื่อรายย่อยทั่วไป</MenuItem>
                                <MenuItem value={'สินเชื่อรายใหญ่'}>สินเชื่อรายใหญ่</MenuItem>
                                <MenuItem value={'อื่นๆ'}>อื่นๆ</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item display={test4 === 'อื่นๆ' ? '' : 'none'}>
                        <TextField id="filterProdName" label="search" variant="outlined" value={filterProdName} onChange={handleFilterProdName} />
                    </Grid>
                </>
            )
        } else if (value[index] == 5) {
            if (org_name === "NVCASH") {
                return (<></>)
            } else {
                return (
                    <>
                        {/* <Grid item>
                            <TextField id="outlined-basic" label="Contract group" variant="outlined" value={test5} onChange={hantest5} />
                        </Grid> */}

                        <Grid item >
                            <FormControl style={{ width: 195 }} >
                                <InputLabel id="demo-simple-select-label">Optional</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={test5}
                                    label="Optional"
                                    onChange={hantest5}
                                >
                                    <MenuItem value={'ลูกค้ารายย่อย'}>ลูกค้ารายย่อย</MenuItem>
                                    <MenuItem value={'ลูกค้ารายกลาง'}>ลูกค้ารายกลาง</MenuItem>
                                    <MenuItem value={'ลูกค้ารายใหญ่'}>ลูกค้ารายใหญ่</MenuItem>
                                    <MenuItem value={'หนี้สูญทางบัญชี'}>หนี้สูญทางบัญชี</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ NP PICO'}>ฝากบันทึก - ลูกหนี้ NP PICO</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ MSCW'}>ฝากบันทึก - ลูกหนี้ MSCW</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ พิโกชลบุรี'}>ฝากบันทึก - ลูกหนี้ พิโกชลบุรี</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ผ่อนสินค้า'}>ฝากบันทึก - ผ่อนสินค้า</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว'}>ฝากบันทึก - ลูกหนี้ปิดบัญชีหนี้สูญรับคืนแล้ว</MenuItem>
                                    <MenuItem value={'ลูกค้ารายใหญ่ - นิติบุคคล'}>ลูกค้ารายใหญ่ - นิติบุคคล</MenuItem>
                                    <MenuItem value={'ลูกค้ารายกลาง - นิติบุคคล'}>ลูกค้ารายกลาง - นิติบุคคล</MenuItem>
                                    <MenuItem value={'ลูกค้ารายย่อย - บุคคลรับประกัน'}>ลูกค้ารายย่อย - บุคคลรับประกัน</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ พัฒนาสินทรัพย์บุคคล'}>ฝากบันทึก - ลูกหนี้ พัฒนาสินทรัพย์บุคคล</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - ลูกหนี้ พิโก เชียงใหม่'}>ฝากบันทึก - ลูกหนี้ พิโก เชียงใหม่</MenuItem>
                                    <MenuItem value={'พิโกเชียงใหม่'}>พิโกเชียงใหม่</MenuItem>
                                    <MenuItem value={'อื่นๆ'}>อื่นๆ</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - BYC'}>ฝากบันทึก - BYC</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - CLS'}>ฝากบันทึก - CLS</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - CLS1'}>ฝากบันทึก - CLS1</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - CLS2'}>ฝากบันทึก - CLS2</MenuItem>
                                    <MenuItem value={'ฝากบันทึก CLS'}>ฝากบันทึก CLS</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - AMC'}>ฝากบันทึก - AMC</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - Cash Krub'}>ฝากบันทึก - Cash Krub</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - Nova Capital'}>ฝากบันทึก - Nova Capital</MenuItem>
                                    <MenuItem value={'ฝากบันทึก - Easy Cap'}>ฝากบันทึก - Easy Cap</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item display={test5 === 'อื่นๆ' ? '' : 'none'}>
                            <TextField id="filterPolicyGroup" label="search" variant="outlined" value={filterPolicyGroup} onChange={handleFilterPolicyGroup} />
                        </Grid>
                    </>
                )
            }

        } else if (value[index] == 6) {
            return (
                <>
                    <Grid item >
                        <FormControl style={{ width: 195 }} >
                            <InputLabel id="demo-simple-select-label">Optional</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={test6}
                                label="Optional"
                                onChange={hantest6}
                            >
                                <MenuItem value={'งวดแรก'}>งวดแรก</MenuItem>
                                <MenuItem value={'งวดปกติ'}>งวดปกติ</MenuItem>
                                <MenuItem value={'หักแล้ว'}>หักแล้ว</MenuItem>
                                <MenuItem value={'อื่นๆ'}>อื่นๆ</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item display={test6 === 'อื่นๆ' ? '' : 'none'}>
                        <TextField id="filterRemarkPay" label="search" variant="outlined" value={filterRemarkPay} onChange={handleFilterRemarkPay} />
                    </Grid>
                </>
            )
        } else if (value[index] == 7) {
            return (
                <>
                    <Grid item >
                        <FormControl style={{ width: 195 }} >
                            <InputLabel id="demo-simple-select-label">Optional</InputLabel>
                            <Select
                                multiple
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={test7}
                                label="Optional"
                                onChange={hantest7}

                            >
                                {users.map((user, index) => {
                                    if (user.active) {
                                        return <MenuItem key={index} value={user.Name}>{user.Name}</MenuItem>
                                    }
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </>
            )
        } else {
        }
    }


    return (
        <Box sx={{ width: '100%' }}>
            {
                rows.length === 0 && !status ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : ""
            }
            <Paper sx={{ width: '100%', mb: 2 }}>
                <Grid display={opened ? 'none' : ''}>
                    {
                        rowsPerFilter > 4 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(4)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 3 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(3)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 2 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(2)}
                            </Grid>
                            : null
                    }
                    {
                        rowsPerFilter > 1 ?
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                {filterGrid(1)}
                            </Grid>
                            : null
                    }
                    <Grid container spacing={2} style={{ padding: '1rem' }}>
                        {filterGrid(0)}
                        <Grid item >
                            <Button variant="outlined" style={{ color: "#333", borderColor: "#333" }} onClick={() => addFilter()}>เพิ่มช่องการค้นหา</Button>
                        </Grid>
                        <Grid item >
                            <Button variant="contained" onClick={() => onClickFilterAction()}>ค้นหา</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table
                        // sx={{ minWidth: 750 }}
                        // aria-labelledby="tableTitle"
                        // size='medium'
                        stickyHeader aria-label="sticky table"
                    >
                        {/* <Table stickyHeader aria-label="sticky table"> */}
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{row.ActionOnDate}</TableCell>
                                            <TableCell>{row.Policy_no}</TableCell>
                                            <TableCell>{row.Ku_name}</TableCell>
                                            <TableCell>{row.ProdName}</TableCell>
                                            <TableCell>{row.PolicyGroup}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.OSB.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.PPP.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.CUMINT.toLocaleString()}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.Interest_rate}{'%'}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.PaymentRemaining}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.Budget2}</TableCell>

                                            {/* <TableCell style={{ textAlign: 'center' }}>{Math.ceil(row.Budget2)+1 > 12 ? 12 : Math.ceil(row.Budget2)+1}</TableCell> */}
                                            <TableCell>{row.last_pay.split(" ")[0]}</TableCell>
                                            <TableCell>{row.remark_pay}</TableCell>
                                            <TableCell>{row.Name}</TableCell>
                                            <TableCell><IconButton onClick={() => onClickIcon(row.id)}><SearchIcon color="primary" /></IconButton></TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[50, 100, 200, 300, 500,]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={1}>
            <MyApp />
        </SnackbarProvider>
    );
}


import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import SideBar from '../dashboard.js';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useLocation } from 'react-router-dom';
import { checkPermission } from '../../utils/permission_utils.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { getMKTDashboardData, updateMKTDashboardData } from '../../services/marketing-services.js';
import MarketingDashboardTable from '../../components/table/marketing-dashboard-table.js';
import MarketingDashboardInputForm from '../../components/form/marketing-dashboard-input-form.js';
const moment = require("moment-timezone")

export default function MarketingDashboard({ }) {

    let token = localStorage.getItem('token')
    let token_org = localStorage.getItem('token_org')
    let org_name = localStorage.getItem('org_name')

    let decode;
    if (token === null) {
        window.location.href = '/login';
    } else {
        decode = jwt_decode(token);
    }
    const location = useLocation();
    // const has_permis = checkPermission(location.pathname, decode);

    const [has_permis, setHasPermis] = useState(true);
    const [loading, setLoading] = useState(false);

    const [selected_date, setSelectedDate] = useState("")
    const [mkt_data, setMKTData] = useState([])
    const [mkt_data_fillter, setMKTDataFillter] = useState([])

    
    const [selected_month, setSelectedMonth] = useState("")
    const [is_open, setIsOpen] = useState(false)
    const [header, setHeader] = useState("")
    const [value, setValue] = useState("")
    const [save_mode, setSaveMode] = useState("")
    const [selected_mongo_id, setSelectedMongoID] = useState("")
    const [selected_mongo_tx_data_index, setSelectedMongoTxDataIndex] = useState("")

    useEffect(() => {
        console.log(has_permis);
        if (!has_permis) {
            window.location.href = '/404';
        }
    }, [])

    const handleSearchButton = async () => {
        setSelectedMonth(selected_date.format("MMMM YYYY"))
        fetchMKTDashboardData()
        // fetchMKTDashboardData(selected_date.format("MMMM YYYY"))
    }

    const calData = (input, output) => {
        output.amount_spent += input.amount_spent === undefined || input.amount_spent === "-" ? 0 : parseInt(input.amount_spent)
        output.reach += input.reach === undefined || input.reach === "-" ? 0 : parseInt(input.reach)
        output.impressions += input.impressions === undefined || input.impressions === "-" ? 0 : parseInt(input.impressions)
        output.link_click += input.link_click === undefined || input.link_click === "-" ? 0 : parseInt(input.link_click)
        output.contact += input.contact === undefined || input.contact === "-" ? 0 : parseInt(input.contact)
        // output.no_test += input.no_test === undefined || input.no_test === "-" ? 0 : parseInt(input.no_test)
        output.total_count += parseInt(input.total_count)
        output.pass_micro_site += parseInt(input.pass_micro_site)
        output.no_pass_micor_site += parseInt(input.no_pass_micor_site)
        output.digital_app += parseInt(input.digital_app)
        output.no_ditgital_app += parseInt(input.no_ditgital_app)
        // output.document_received += input.document_received === undefined || input.document_received === "-" ? 0 : parseInt(input.document_received)
        output.approved += parseInt(input.approved)
        output.reject += parseInt(input.reject)
        output.amount += parseInt(input.amount)
        output.offer_limit += parseInt(input.offer_limit)
        // output.amount_avg += parseFloat(input.amount_avg)
        return output
    }

    const calDailyData = (input, output) => {
        output.document_received += input.document_received === undefined || input.document_received === "-" ? 0 : parseInt(input.document_received)
        return output
    }

    const checkNaNandFinite = (checked_data) => {
        return (isNaN(checked_data) || !isFinite(checked_data))
    }

    const selectValue = (value) => {
        return checkNaNandFinite(value) ? '-' : value > 0 ? value.toFixed(2) : '-'
    }

    const summarizeData = (output) => {
        // console.log("output agency", output.agency);
        // console.log("output total_count", output.total_count);
        // console.log("output pass_micro_site", output.pass_micro_site);
        // console.log("output no_pass_micor_site", output.no_pass_micor_site);
        // console.log("output link_click", output.link_click);
        output.amount_avg = selectValue(parseInt(output.amount)/parseFloat(output.approved))
        output.no_test = selectValue(parseInt(output.link_click)-parseInt(output.pass_micro_site)-parseInt(output.no_pass_micor_site))
        output.percent_no_pass_micro_site = selectValue(parseInt(output.no_test) * 100 / parseInt(output.link_click))
        output.percent_pass_micro_site = selectValue(parseInt(output.pass_micro_site) * 100 / parseInt(output.link_click))
        output.percent_no_ditgital_app = selectValue(parseInt(output.no_ditgital_app) * 100 / parseInt(output.link_click))
        output.percent_document_received = selectValue(parseInt(output.document_received) * 100 / parseInt(output.digital_app))
        output.percent_approved = selectValue(parseInt(output.approved) * 100 / parseInt(output.document_received))
        output.percent_reject = selectValue(parseInt(output.reject) * 100 / parseInt(output.document_received))
        output.cost_per_click = selectValue(parseInt(output.amount_spent) / parseInt(output.link_click))
        output.cost_per_contact = selectValue(parseInt(output.amount_spent) / parseInt(output.contact))
        output.cost_per_pass_micro_site = selectValue(parseInt(output.amount_spent) / parseInt(output.pass_micro_site))
        output.cost_per_approved = selectValue(parseInt(output.amount_spent)/parseInt(output.approved))
        output.cost_per_million = selectValue(parseInt(output.amount_spent)* 1000000/parseInt(output.amount))
        // output.amount_avg = output.amount_avg.toFixed(2)
        return output
    }

    const summarizeDailyData = (output, temp_item) => {
        temp_item.amount_spent = output.amount_spent
        temp_item.reach = output.reach
        temp_item.impressions = output.impressions
        temp_item.link_click = output.link_click
        temp_item.contact = output.contact

        temp_item.amount_avg = selectValue(parseInt(output.amount)/parseFloat(temp_item.approved))
        temp_item.no_test = selectValue(parseInt(output.link_click)-parseInt(temp_item.total_count))
        temp_item.percent_no_pass_micro_site = selectValue(parseInt(temp_item.no_test) * 100 / parseInt(output.link_click))
        temp_item.percent_pass_micro_site = selectValue(parseInt(temp_item.pass_micro_site) * 100 / parseInt(output.link_click))
        temp_item.percent_no_ditgital_app = selectValue(parseInt(temp_item.no_ditgital_app) * 100 / parseInt(output.link_click))
        // output.percent_document_received = selectValue(parseInt(output.document_received) * 100 / parseInt(output.digital_app))
        // output.percent_approved = selectValue(parseInt(output.approved) * 100 / parseInt(output.document_received))
        // output.percent_reject = selectValue(parseInt(output.reject) * 100 / parseInt(output.document_received))
        temp_item.cost_per_click = selectValue(parseInt(output.amount_spent) / parseInt(output.link_click))
        temp_item.cost_per_contact = selectValue(parseInt(output.amount_spent) / parseInt(output.contact))
        temp_item.cost_per_pass_micro_site = selectValue(parseInt(output.amount_spent) / parseInt(temp_item.pass_micro_site))
        temp_item.cost_per_approved = selectValue(parseInt(output.amount_spent)/parseInt(temp_item.approved))
        temp_item.cost_per_million = selectValue(parseInt(output.amount_spent)* 1000000/parseInt(temp_item.amount))
        // output.amount_avg = output.amount_avg.toFixed(2)
        return temp_item
    }

    const initSummarizeData = (date, agency) => {
        return {
            'date': date,
            'agency': agency,
            'brand': "Total",
            'mkt_channel': "Total",
            'amount_spent': 0,
            'link_click': 0,
            'no_test': 0,
            'total_count': 0,
            'pass_micro_site': 0,
            'no_pass_micor_site': 0,
            'digital_app': 0,
            'no_ditgital_app': 0,
            'percent_pass_micro_site': 0,
            'percent_no_pass_micro_site': 0,
            'document_received': 0,
            'approved': 0,
            'reject': 0,
            'percent_document_received': 0,
            'percent_approved': 0,
            'percent_reject': 0,
            'amount': 0,
            'cost_per_click': 0,
            'cost_per_pass_micro_site': 0,
            'cost_per_approved': 0,
            'cost_per_million': 0,
            'reach': 0,
            'impressions': 0,
            'contact': 0,
            'offer_limit': 0,
            'amount_avg': 0,
            'note': "",
            'percent_no_ditgital_app':0
        }
    }
    const fetchMKTDashboardData = async () => {
        setLoading(true)
        let mkt_res_data = await getMKTDashboardData(selected_date.toString())
        if(mkt_res_data.data.length > 0){
            let temp_list = []
            let row_index = 0
            let monthly_summarize = initSummarizeData(selected_date.format("MMMM YYYY"), "Total (Month)")
            let week_for_check = moment(mkt_res_data.data[0].date,'YYYY-MM-DD').week()
            // console.log(week_for_check);
            let weekly_summarize = initSummarizeData("Week "+moment(mkt_res_data.data[0].date,'YYYY-MM-DD').week().toString(), "Total (Week)")
            for (let mkt_res_row of mkt_res_data.data) {
                if(week_for_check !== moment(mkt_res_row.date,'YYYY-MM-DD').week()){
                    week_for_check = moment(mkt_res_row.date,'YYYY-MM-DD').week()
                    weekly_summarize = summarizeData(weekly_summarize)
                    temp_list.push(weekly_summarize)
                    weekly_summarize = initSummarizeData("Week "+moment(mkt_res_row.date,'YYYY-MM-DD').week().toString(), "Total (Week)")
                }
                let daily_summarize = initSummarizeData(moment(mkt_res_row.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),"Total")
                for (let tx_data_index in mkt_res_row.tx_data) {
                    let mkt_tx_row = mkt_res_row.tx_data[tx_data_index]
                    daily_summarize = calData(mkt_tx_row, daily_summarize)
                    weekly_summarize = calData(mkt_tx_row, weekly_summarize)
                    monthly_summarize = calData(mkt_tx_row, monthly_summarize)
                    let temp_item = {
                        '_id': mkt_res_row._id,
                        'id': row_index,
                        'tx_data_index': tx_data_index,
                        'date': moment(mkt_tx_row.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                        'agency': mkt_tx_row.agency,
                        'brand': mkt_tx_row.brand,
                        'mkt_channel': mkt_tx_row.mkt_channel,
                        'amount_spent': mkt_tx_row.amount_spent === undefined ? '-' : mkt_tx_row.amount_spent,
                        'reach': mkt_tx_row.reach === undefined ? '-' : mkt_tx_row.reach,
                        'impressions': mkt_tx_row.impressions === undefined ? '-' : mkt_tx_row.impressions,
                        'link_click': mkt_tx_row.link_click === undefined ? '-' : mkt_tx_row.link_click,
                        'contact': mkt_tx_row.contact === undefined ? '-' : mkt_tx_row.contact,
                        'no_test': mkt_tx_row.no_test === undefined ? '-' : mkt_tx_row.no_test,
                        'percent_no_test': mkt_tx_row.percent_no_test === undefined ? '-' : mkt_tx_row.percent_no_test,
                        'total_count': mkt_tx_row.total_count,
                        'pass_micro_site': mkt_tx_row.pass_micro_site,
                        'no_pass_micor_site': mkt_tx_row.no_pass_micor_site,
                        'digital_app': mkt_tx_row.digital_app,
                        'no_ditgital_app': mkt_tx_row.no_ditgital_app,
                        'percent_pass_micro_site': mkt_tx_row.percent_pass_micro_site === undefined ? '-' : mkt_tx_row.percent_pass_micro_site,
                        'percent_no_pass_micro_site': mkt_tx_row.percent_no_pass_micro_site === undefined ? '-' : mkt_tx_row.percent_no_pass_micro_site,
                        'percent_no_ditgital_app': mkt_tx_row.percent_no_ditgital_app === undefined ? '-' : mkt_tx_row.percent_no_ditgital_app,
                        'document_received': mkt_tx_row.document_received === undefined ? '-' : mkt_tx_row.document_received,
                        'approved': mkt_tx_row.approved,
                        'reject': mkt_tx_row.reject,
                        'percent_document_received': mkt_tx_row.percent_document_received === undefined ? '-' : mkt_tx_row.percent_document_received,
                        'percent_approved': mkt_tx_row.percent_approved === undefined ? '-' : mkt_tx_row.percent_approved,
                        'percent_reject': mkt_tx_row.percent_reject === undefined ? '-' : mkt_tx_row.percent_reject,
                        'amount': mkt_tx_row.amount,
                        'offer_limit': mkt_tx_row.offer_limit,
                        'amount_avg': mkt_tx_row.amount_avg,
                        'cost_per_click': mkt_tx_row.cost_per_click === undefined ? '-' : mkt_tx_row.cost_per_click,
                        'cost_per_contact': mkt_tx_row.cost_per_contact === undefined ? '-' : mkt_tx_row.cost_per_contact,
                        'cost_per_pass_micro_site': mkt_tx_row.cost_per_pass_micro_site === undefined ? '-' : mkt_tx_row.cost_per_pass_micro_site,
                        'cost_per_approved': mkt_tx_row.cost_per_approved === undefined ? '-' : mkt_tx_row.cost_per_approved,
                        'cost_per_million': mkt_tx_row.cost_per_million === undefined ? '-' : mkt_tx_row.cost_per_million,
                        'note': mkt_tx_row.note === undefined ? '-' : mkt_tx_row.note,
                    }
                    row_index++
                    temp_list.push(temp_item)
                }
                console.log("total data", mkt_res_row.total_data);
                let mkt_tx_row = mkt_res_row.total_data
                weekly_summarize = calDailyData(mkt_tx_row, weekly_summarize)
                monthly_summarize = calDailyData(mkt_tx_row, monthly_summarize)
                let temp_item = {
                    '_id': mkt_res_row._id,
                    'id': row_index,
                    'tx_data_index': -1,
                    'date': moment(mkt_tx_row.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                    'agency': mkt_tx_row.agency,
                    'brand': mkt_tx_row.brand,
                    'mkt_channel': mkt_tx_row.mkt_channel,
                    'amount_spent': mkt_tx_row.amount_spent === undefined ? '-' : mkt_tx_row.amount_spent,
                    'reach': mkt_tx_row.reach === undefined ? '-' : mkt_tx_row.reach,
                    'impressions': mkt_tx_row.impressions === undefined ? '-' : mkt_tx_row.impressions,
                    'link_click': mkt_tx_row.link_click === undefined ? '-' : mkt_tx_row.link_click,
                    'contact': mkt_tx_row.contact === undefined ? '-' : mkt_tx_row.contact,
                    'no_test': mkt_tx_row.no_test === undefined ? '-' : mkt_tx_row.no_test,
                    'percent_no_test': mkt_tx_row.percent_no_test === undefined ? '-' : mkt_tx_row.percent_no_test,
                    'total_count': mkt_tx_row.total_count,
                    'pass_micro_site': mkt_tx_row.pass_micro_site,
                    'no_pass_micor_site': mkt_tx_row.no_pass_micor_site,
                    'digital_app': mkt_tx_row.digital_app,
                    'no_ditgital_app': mkt_tx_row.no_ditgital_app,
                    'percent_pass_micro_site': mkt_tx_row.percent_pass_micro_site === undefined ? '-' : mkt_tx_row.percent_pass_micro_site,
                    'percent_no_pass_micro_site': mkt_tx_row.percent_no_pass_micro_site === undefined ? '-' : mkt_tx_row.percent_no_pass_micro_site,
                    'percent_no_ditgital_app': mkt_tx_row.percent_no_ditgital_app === undefined ? '-' : mkt_tx_row.percent_no_ditgital_app,
                    'document_received': mkt_tx_row.document_received === undefined ? '-' : mkt_tx_row.document_received,
                    'approved': mkt_tx_row.approved,
                    'reject': mkt_tx_row.reject,
                    'percent_document_received': mkt_tx_row.percent_document_received === undefined ? '-' : mkt_tx_row.percent_document_received,
                    'percent_approved': mkt_tx_row.percent_approved === undefined ? '-' : mkt_tx_row.percent_approved,
                    'percent_reject': mkt_tx_row.percent_reject === undefined ? '-' : mkt_tx_row.percent_reject,
                    'amount': mkt_tx_row.amount,
                    'offer_limit': mkt_tx_row.offer_limit,
                    'amount_avg': mkt_tx_row.amount_avg,
                    'cost_per_click': mkt_tx_row.cost_per_click === undefined ? '-' : mkt_tx_row.cost_per_click,
                    'cost_per_contact': mkt_tx_row.cost_per_contact === undefined ? '-' : mkt_tx_row.cost_per_contact,
                    'cost_per_pass_micro_site': mkt_tx_row.cost_per_pass_micro_site === undefined ? '-' : mkt_tx_row.cost_per_pass_micro_site,
                    'cost_per_approved': mkt_tx_row.cost_per_approved === undefined ? '-' : mkt_tx_row.cost_per_approved,
                    'cost_per_million': mkt_tx_row.cost_per_million === undefined ? '-' : mkt_tx_row.cost_per_million,
                    'note': mkt_tx_row.note === undefined ? '-' : mkt_tx_row.note,
                }
                // temp_list.push(temp_item)
                console.log("before cal", temp_item);
                summarizeDailyData(daily_summarize, temp_item)
                console.log("after cal", temp_item);
                temp_list.push(temp_item)
                // temp_list.push
            }
            weekly_summarize = summarizeData(weekly_summarize)
            temp_list.push(weekly_summarize)
            monthly_summarize = summarizeData(monthly_summarize)
            temp_list.push(monthly_summarize)
            setMKTData(temp_list)
            let filteredArray = []
            if (org_name === "NVCASH") {
                filteredArray = temp_list.filter(item => item.brand === "NVCASH");
            } else {
                filteredArray = temp_list
            }
            setMKTDataFillter(filteredArray)
        }else{
            alert("ไม่พบข้อมูลที่ท่านเลือก กรุณาเลือกเดือนใหม่")
        }
        setLoading(false)
  

    }

    const handleEditClick = (mode, object_id, tx_data_index, old_value) => {
        setSaveMode(mode)
        setSelectedMongoID(object_id)
        setSelectedMongoTxDataIndex(tx_data_index)
        setValue(old_value)
        if (mode === "note") {
            setHeader("แก้ไขหมายเหตุ")
        } else if (mode === "document_received") {
            setHeader("แก้ไข Document Received")
        } else if (mode === "link_click") {
            setHeader("แก้ไข Link Click")
        } else if (mode === "amount_spent") {
            setHeader("แก้ไข Amount Spent")
        }else if (mode === "reach") {
            setHeader("แก้ไข Reach")
        }else if (mode === "impressions") {
            setHeader("แก้ไข Impressions")
        }else if (mode === "contact") {
            setHeader("แก้ไข Contact")
        }
        setIsOpen(true)
    }

    const handleSaveButton = async () => {
        setIsOpen(true)
        let temp_data = await mkt_data.find(mkt => mkt._id === selected_mongo_id && mkt.tx_data_index === selected_mongo_tx_data_index)
        let temp_date = temp_data.date
        temp_data.date = moment(temp_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
        if (save_mode === "note") {
            temp_data[save_mode] = value
        } else if (isNaN(value)) {
            alert("กรุณากรอกตัวเลขเท่านั้น")
            return;
        } else {
            temp_data[save_mode] = value
            if (save_mode === "link_click") {
                temp_data.no_test = parseInt(value) -parseInt(temp_data.pass_micro_site)-parseInt(temp_data.no_pass_micor_site)
                temp_data.percent_no_ditgital_app =  selectValue(parseInt(temp_data.no_ditgital_app) * 100 / parseInt(value))
                temp_data.percent_pass_micro_site = selectValue(parseInt(temp_data.pass_micro_site) * 100 / parseInt(value))
                temp_data.percent_no_pass_micro_site = selectValue(parseInt(temp_data.no_test) * 100 / parseInt(value))
                temp_data.cost_per_click = selectValue(parseInt(temp_data.amount_spent)/ parseInt(value))
            } else if (save_mode === "amount_spent") {
                temp_data.cost_per_click = selectValue(parseInt(value) / parseInt(temp_data.link_click))
                temp_data.cost_per_contact = selectValue(parseInt(value) / parseInt(temp_data.contact))
                temp_data.cost_per_pass_micro_site = selectValue(parseInt(value) / parseInt(temp_data.pass_micro_site))
                temp_data.cost_per_approved = selectValue(parseInt(value) / parseInt(temp_data.approved))
                temp_data.cost_per_million = selectValue(parseInt(value) * 1000000 / parseInt(temp_data.amount))
            } else if (save_mode === "document_received") {
                temp_data.percent_document_received = selectValue(parseInt(value) * 100 / parseInt(temp_data.digital_app))
                temp_data.percent_approved = selectValue(parseInt(temp_data.approved) * 100 / parseInt(value))
                temp_data.percent_reject = selectValue(parseInt(temp_data.reject) * 100 / parseInt(value))
            }else if (save_mode === "contact") {
                temp_data.cost_per_contact = selectValue(parseInt(temp_data.amount_spent) / parseInt(value))
            }
        }
        // console.log("temp_data", temp_data);
        updateMKTDashboardData(temp_data)
            .then(res => {
                console.log(res);
                setIsOpen(false)
                handleCloseModal()
                fetchMKTDashboardData()
            })
            .catch(err => {
            })
        // if(save_mode !== "document_received"){
        //     // let daily_data = await mkt_data.find(mkt => mkt._id === selected_mongo_id)
        //     for(let mkt_item of mkt_data){
        //         if(mkt_item._id === selected_mongo_id && mkt_item.agency !== 'Total'){
        //             console.log("mkt_item", mkt_item);
        //         }
        //     }
        //     // console.log("daily_data", daily_data);
        // }
    }

    const handleCloseModal = () => {
        setSaveMode("")
        setSelectedMongoID("")
        setSelectedMongoTxDataIndex("")
        setValue("")
        setIsOpen(false)
    }

    return (
        <>
            {
                has_permis && (
                    <div className='customer-list' style={{ margin: '2rem' }}>
                        <SideBar />
                        <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                            <Grid item xs>
                                <Typography variant='h4' style={{ fontWeight: '' }}>Marketing Dashboard</Typography>
                            </Grid>
                        </Grid>
                        <Paper elevation={3}>
                            <Grid container spacing={2} style={{ padding: '1rem' }}>
                                <Grid item>
                                    <Typography variant='h5' style={{ fontWeight: '' }}>เลือกเดือนที่ต้องการเรียกดู</Typography>
                                </Grid>
                                <Grid item>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DesktopDatePicker
                                            label="เดือนที่เลือกดู"
                                            inputFormat="MM/YYYY"
                                            openTo="month"
                                            views={['year', 'month']}
                                            minDate="2023-09-01"
                                            value={selected_date}
                                            onChange={(date) => setSelectedDate(date)}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' size='small'
                                        onClick={() => handleSearchButton()}
                                    >
                                        ค้นหา
                                    </Button>
                                </Grid>
                            </Grid>
                            {loading && ( // Render loading indicator when open is true
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={loading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            )}
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <MarketingDashboardTable
                                    selected_month={selected_month}
                                    mkt_data={mkt_data_fillter}
                                    handleEditClick={handleEditClick}
                                />
                            </Box>
                        </Paper>
                    </div>
                )
            }
            <MarketingDashboardInputForm
                is_open={is_open}
                handleCloseModal={handleCloseModal}
                header={header}
                value={value}
                setValue={setValue}
                handleSaveButton={handleSaveButton}
            />
        </>
        // <>
        //     {loading && ( // Render loading indicator when open is true
        //         <Backdrop
        //             sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //             open={loading}
        //         >
        //             <CircularProgress color="inherit" />
        //         </Backdrop>
        //     )}
        // </>
    )
}
/* eslint-disable no-unused-vars */

import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import { DataGrid, GridColumn } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Loading from '../../components/loading.js';
import * as statementHttpServices from '../../services/statement_service.js';
import { confirmAlert } from 'react-confirm-alert';
import { Checkbox } from '@mui/material';

export default function App() {
    const { statement_id } = useParams();

    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    const [rows, setRows] = useState([]);
    const [data, setData] = useState();
    const [additionalData, setAdditionalData] = useState([])
    const [pageSize, setPageSize] = useState(25)
    const rowsPerPageOptions = [25, 50, 100]
    const [isLoading, setIsLoading] = useState(true);

    const account_number = localStorage.getItem("account_number")

    const handlePageSizeChange = (pageSize) => {
        // Handle the change in page size
        console.log(`Page size changed to: ${pageSize}`);
        setPageSize(pageSize)
        // You can perform any other necessary logic here
    };

    useEffect(() => {
        statementHttpServices.getOcrStatement(statement_id)
            .then((res) => {
                const data = res.data.data[0];
                console.log('data>>>', data)
                setData(data)

                let row = data.data.account_statement
                    .map((item, index) => {
                        function removeCommasFromString(str) {
                            if (typeof str === 'string') {
                                return str.replace(/,/g, "");
                            }
                            return str; // Return unchanged if it's not a string
                        }

                        const withdraw = removeCommasFromString(item.withdraw);
                        const deposite = removeCommasFromString(item.deposite);
                        const balance = removeCommasFromString(item.balance);

                        return {
                            ...item,
                            withdraw: item.withdraw === "" ? item.withdraw : parseFloat(withdraw),
                            deposite: item.deposite === "" ? item.deposite : parseFloat(deposite),
                            balance: item.balance === "" ? item.balance : parseFloat(balance),
                            id: index + 1
                        };
                    });

                setRows(row)

                setIsLoading(false)
            })
            .catch((err) => console.log(err));
    }, []); // Empty dependency array to run the effect only once

    const handleSalaryCheckboxClick = useCallback((row) => {
        const raw = Object.assign([], rows)
        const tempIndex = raw.findIndex(r => r.id === row.id)
        if (tempIndex > -1) {
            raw[tempIndex].isSalary = !raw[tempIndex].isSalary
        }
        
        setRows(raw)
    }, [rows])

    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: 'รับเงินเดือน',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => {
                const isItemSelected = !!row.isSalary
                const labelId = `enhanced-table-checkbox-${row.id}`;
                return (
                    // TODO find other hardcoded values
                    row.title === 'ยอดยกมา' ? <></> :
                    <Checkbox
                        onClick={(event) => handleSalaryCheckboxClick(row)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                            'aria-labelledby': labelId,
                        }}
                    />
                )
            }
        },
        {
            field: 'date',
            headerName: 'วันที่',
            type: 'date',
            width: 100,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'time',
            headerName: 'เวลา*',
            width: 100,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'title',
            headerName: 'title*',
            width: 200,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'withdraw',
            headerName: 'withdraw*',
            type: 'number',
            width: 200,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'deposite',
            headerName: 'deposite*',
            type: 'number',
            width: 200,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'balance',
            headerName: 'balance',
            type: 'number',
            width: 200,
            editable: false,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'channel',
            headerName: 'channel*',
            width: 200,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'description',
            headerName: 'หมายเหตุ*',
            flex: 1,
            minWidth: 300,
            editable: true,
            headerAlign: 'center',
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: "del",
            headerName: "",
            width: 100,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) =>
                <Button color="error" onClick={() => { console.log(row); deleteObjectById(row) }}>
                    DELETE
                </Button>,
        },
    ], [deleteObjectById, handleSalaryCheckboxClick]);


    if (isLoading) {
        return <Loading />; // Display loading message or spinner
    }

    return (
        <div className='customer-list' style={{ margin: '2rem' }}>
            <SideBar />

            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>Edit Ocr Statement</Typography>
                </Grid>
            </Grid>

            <Paper elevation={3} style={{ marginBottom: '1rem' }}>
                <Grid container padding={2}>
                    <Grid item xs>
                        <Typography variant='h6'>account info</Typography>
                    </Grid>
                </Grid>

                {console.log('asdasd', data)}
                <Grid container rowSpacing={2} padding={2} columnSpacing={2}>
                    <Grid item xs={8}>
                        <TextField id="account_address-basic" label="account_address" variant="outlined" fullWidth value={data.data.account_info.account_address} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="account_branch-basic" label="account_branch" variant="outlined" fullWidth value={data.data.account_info.account_branch} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="account_info_1-basic" label="account_info_1" variant="outlined" fullWidth value={data.data.account_info.account_info_1} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="account_name-basic" label="account_name" variant="outlined" fullWidth value={data.data.account_info.account_name} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="account_number-basic" label="account_number" variant="outlined" fullWidth value={data.data.account_info.account_number} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="account_period-basic" label="account_period" variant="outlined" fullWidth value={data.data.account_info.account_period} />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField id="account_ref-basic" label="account_ref" variant="outlined" fullWidth value={data.data.account_info.account_ref} />
                    </Grid>
                </Grid>
            </Paper>

            <Paper elevation={3} style={{ marginBottom: '1rem' }}>
                <Grid container padding={2} marginBottom={2}>
                    <Grid item xs>
                        <Typography variant='h6'>account statement</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={() => addRow(rows)}>ADD NEW ROW</Button>
                    </Grid>
                </Grid>
                <Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize} // Set the desired number of rows per page
                        rowsPerPageOptions={rowsPerPageOptions} // Provide available options
                        onPageSizeChange={handlePageSizeChange} // Handle page size change
                        autoHeight
                        disableRowSelectionOnClick
                        disableSelectionOnClick
                        onCellEditCommit={(params, e) => handleEditCellChange(params)}
                    />
                    <div style={{ padding: '15px', textAlign: 'right' }}>
                        <Button variant="contained" color='success' sx={{ width: '100px' }} onClick={() => saveData()}>Save</Button>
                    </div>
                </Box>
            </Paper>

            {/* <Paper elevation={3} style={{ marginBottom: '1rem' }}>
                <Grid container padding={2}>
                    <Grid item xs>
                        <Typography variant='h6'>account_summary</Typography>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={2} padding={2} marginBottom={2} columnSpacing={2}>
                    <Grid item xs={3}>
                        <TextField id="account_total_balance-basic" label="account_total_balance" variant="outlined" fullWidth value={data.statement.account_info.account_summary.account_total_balance} />
                    </Grid>
                    <Grid item xs={2.25}>
                        <TextField id="account_total_deposite-basic" label="account_total_deposite" variant="outlined" fullWidth value={data.statement.account_info.account_summary.account_total_deposite} />
                    </Grid>
                    <Grid item xs={2.25}>
                        <TextField id="account_total_deposite_number-basic" label="account_total_deposite_number" variant="outlined" fullWidth value={data.statement.account_info.account_summary.account_total_deposite_number} />
                    </Grid>
                    <Grid item xs={2.25}>
                        <TextField id="account_total_withdraw-basic" label="account_total_withdraw" variant="outlined" fullWidth value={data.statement.account_info.account_summary.account_total_withdraw} />
                    </Grid>
                    <Grid item xs={2.25}>
                        <TextField id="account_total_withdraw_number-basic" label="account_total_withdraw_number" variant="outlined" fullWidth value={data.statement.account_info.account_summary.account_total_withdraw_number} />
                    </Grid>
                </Grid>
            </Paper> */}
        </div>
    )

    function addRow(row) {
        var today = new Date();
        const lastData = rows[row.length - 1]

        const obj = {
            id: lastData.id + 1,
            date: formatDate(today),
            time: formatTime(today),
            title: "",
            withdraw: "",
            deposite: "",
            balance: lastData.balance,
            channel: "",
            description: "",
        }

        setRows((prevRows) => [...prevRows, obj]);
    }

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);

        return `${day}-${month}-${year}`;
    }

    function formatTime(date) {
        var hours = date.getHours().toString().padStart(2, '0');
        var minutes = date.getMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    }

    function handleConfirm(row) {
        const result = window.confirm('ลบรายการเดินบัญชีธนาคารของวันที่ \n\nสําคัญ: หากลบประวัติรายการเดินบัญชีธนาคารที่บันทึกไว้ใน TFM คุณจะกู้คืนข้อมูลกลับมาไม่ได้');
        if (result) {
            const balance = parseFloat(row.balance) || 0;
            const withdraw = parseFloat(row.withdraw) || 0;
            const deposite = parseFloat(row.deposite) || 0;

            // User clicked OK
            console.log('User confirmed');

            const newBalance = balance + withdraw - deposite

            let updatedBalance = newBalance
            const updated = rows.filter(r => r.id !== row.id).map(r => {
                if (r.id > row.id) {
                    const updateWithdraw = parseFloat(r.withdraw) || 0;
                    const updateDeposit = parseFloat(r.deposite) || 0;

                    console.log(updateWithdraw, updateDeposit)
                    updatedBalance = updatedBalance - updateWithdraw + updateDeposit;

                    return { ...r, balance: updatedBalance };
                }

                return r;
            });

            setRows(updated);
        } else {
            // User clicked Cancel
            console.log('User cancelled');
        }
    };

    function deleteObjectById(row) {
        handleConfirm(row)
    }

    function handleEditCellChange(params) {
        console.log()
        // Update the corresponding data in the updatedRows array

        const newValue = params.value

        let newBalance
        const updated = rows.map((r) => {
            if (r.id === params.id) {
                const balance = parseFloat(r.balance) || 0;
                const withdraw = parseFloat(r.withdraw) || 0;
                const deposite = parseFloat(r.deposite) || 0;

                switch (params.field) {
                    case 'withdraw':
                        newBalance = balance - (newValue - withdraw)
                        break;
                    case 'deposite':
                        newBalance = balance + (newValue - deposite)
                        break;
                    default:
                        newBalance = balance
                    // code block
                }

                return { ...r, [params.field]: params.value, balance: newBalance };

            } else if (r.id > params.id) {
                const withdraw = parseFloat(r.withdraw) || 0;
                const deposite = parseFloat(r.deposite) || 0;

                newBalance = newBalance - withdraw + deposite
                return { ...r, balance: newBalance };
            } else {
                return r
            }
        })

        setRows(updated);
    }

    function saveData() {
        confirmAlert({
            title: 'บันทึกข้อมูล',
            message: 'คุณแน่ใจหรือไม่ว่าต้องการบันทึกข้อมูล?',
            buttons: [
                {
                    label: 'ตกลง',
                    onClick: () => {
                        const body = {
                            statementId: statement_id,
                            data: [rows],
                        }

                        console.log(body)

                        statementHttpServices.editOcrStatement(body)
                            .then((response) => {
                                console.log("Statement edited successfully:", response);
                                // window.history.back();
                                successSaveData(true)
                            })
                            .catch((error) => {
                                console.error("Error editing statement:", error);
                                successSaveData(false)
                            });
                    }
                },
                {
                    label: 'ยกเลิก',
                    onClick: () => {
                        console.log("cancel")
                    }
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        })
        // const obj = { ...data }; // Create a copy of the data object
        // obj.statement.account_statement = rows

        // async function fetchData() {
        //     try {
        //         const response = statementHttpServices.saveEditStatement(obj) // เปลี่ยน URL เป็น URL ของ API ที่คุณต้องการเรียกใช้
        //         const data = await response.json();
        //         console.log(data);
        //     } catch (error) {
        //         // console.log('เกิดข้อผิดพลาดในการเรียกใช้ API:', error);
        //     }
        // }

        // fetchData();
    }

    function successSaveData(success = true) {
        const buttons = [
            {
                label: 'ตกลง',
                onClick: () => {

                }
            }
        ]
        if (success) {
            buttons.push({
                label: 'กลับหน้ารายการไฟล์',
                onClick: () => {
                    window.history.back();
                }
            })
        }
       
        confirmAlert({
            title: success ? 'บันทึกข้อมูลสำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ',
            buttons: buttons,
            overlayClassName: "overlay-custom-class-name"
        })
    }
}
/* eslint-disable no-unused-vars */

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import SideBar from '../dashboard.js'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
// import backdrop
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import * as DirectDebit from '../../services/direct_debit.js';
import * as LoginService from '../../services/login_service.js';

// components
import MyComponent from '../../components/filter.js';
import { useNavigate } from "react-router-dom";
import { checkPermission } from '../../utils/permission_utils.js';
import { useLocation } from 'react-router-dom';
export default function App() {
    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = React.useState(true);
    const [rows_user, setRowsUser] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const has_permission = checkPermission(location.pathname, decoded);
    let org_name = localStorage.getItem('org_name')
    React.useEffect(() => {
        if (!has_permission) {
            window.location.href = '/404';
        }
        fetchData()

    }, [])

    const fetchData = async () => {
        let res = await DirectDebit.getUser(token)
        let user_brand = await LoginService.getAllBrandUser(token)
        const mergedArr = res.data.data.reduce((acc, obj1) => {
            const obj2 = user_brand.data.data.find(obj2 => String(obj2.contact_id) === obj1.contract_id)
            return [...acc, { ...obj1, ...obj2 }];
        }, []);
        let filteredArray =[]
        if (org_name === "NVCASH") {
            filteredArray = mergedArr.filter(item => item.contact_brand === "NVCASH");
       } else {
            filteredArray = res.data.data
       }
        setRowsUser(filteredArray)
    }
    const handleClick = (e, value) => {
        console.log(value.row)
        navigate("/direct_debit/customer_details", {
            state: value.row,
        });

    }
    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 0,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'name',
            headerName: 'ผู้กู้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.name || ''} ${params.row.lastname || ''}`,
        },
        {
            field: 'type',
            headerName: 'ประเภทลูกหนี้',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'offer_limit',
            headerName: 'วงเงิน',
            width: 200,
            editable: true,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'installment_offered',
            headerName: 'งวดทั้งหมด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',

        },
        {
            field: 'register_status',
            headerName: 'สถานะการลงทะเบียน',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                `${params.row.token_account != null ? 'สมัครแล้ว' : 'กำลังพิจารณา'}`,
        },
        {
            field: 'last_pay_date',
            headerName: 'วันที่ชำระล่าสุด',
            width: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>

                `${params.row.last_pay_date == null ? "-" : params.row.last_pay_date.split("T")[0]}`,
        },
        {
            field: 'Edit',
            headerName: 'Detail',
            width: 120,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                return (

                    <IconButton onClick={(event) => {
                        handleClick(event, cellValues);
                    }}><SearchIcon color="primary" /></IconButton>
                );
            }
        },

        // {
        // field: '',
        // headerName: '',
        // description: 'This column has a value getter and is not sortable.',
        // sortable: false,
        // type: 'number',
        // width: 160,
        // valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    // rows
    // TODO CARE DATA

    return (
        <>

            {has_permission && (
                <div className='customer-list' style={{ margin: '2rem' }}>
                    <SideBar />
                    <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                        <Grid item xs>
                            <Typography variant='h4' style={{ fontWeight: '' }}>Customer List</Typography>
                        </Grid>
                    </Grid>
                    {
                        false ?
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            : ""
                    }

                    <Paper elevation={3} >
                        <MyComponent />
                        <Box
                            sx={{
                                height: 630,
                                width: '100%',
                                // bgcolor:'black'
                            }}
                        >
                            <DataGrid
                                rows={rows_user}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1.5,
                                    },
                                }}

                            />
                        </Box>
                    </Paper>
                </div>
            )}
        </>
    )
}
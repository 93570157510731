/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SideBar from './../dashboard'
import jwt_decode from "jwt-decode";
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import * as fileHttpServices from './../../services/file_management_services'
import * as LoginHttpServices from './../../services/login_service'

export default function App() {
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([])
    let org_name = localStorage.getItem('org_name')

    let token = localStorage.getItem('token')
    let decoded

    if (token === null) {
        window.location.href = '/login'
    } else {
        decoded = jwt_decode(token)
    }

    // backdrop
    const [open, setOpen] = useState(true);

    // columns
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 250,
            headerAlign: 'center',
            align: 'center',
            hide: true
        },
        {
            field: 'contract_no',
            headerName: 'เลขที่สัญญา',
            width: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'contract_id',
            headerName: 'Contract ID',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'name',
            headerName: 'ชื่อ-สกุล',
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'offer_limit',
            headerName: 'วงเงิน',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'conclude',
            headerName: 'ผลการพิจารณา',
            width: 250,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: "ncb",
            headerName: "File NCB",
            width: 250,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ row }) =>
                <Button onClick={() => window.open('/ncb/list/' + row.contract_id)}>
                    NCB
                </Button>,
        },
    ];

    const getToken = () => {
        let token = localStorage.getItem('token_org')
        return token
    }

    const handleSubmit = async () => {
        if (search === undefined || search === '') {
            alert('กรุณากรอกเลขบัตรประชาชน')
        } else {
            if (org_name === 'NVCASH') {
              let res =  await chekBrand()
              console.log(res.data.data)
              if(res.data.length !==0 && res.data.data.brand==='NVCASH'){
                await fetchData()
              }else{
                alert('ค้นหาไม่พบ')
              }
            } else {
                await fetchData()
            }

        }
    }
    const chekBrand = async () => {
        let obj = {
            id_number: search
        }
        let res = await LoginHttpServices.checkBrandUser(obj)
        return res
    }
    const fetchData = async () => {
        fileHttpServices.getContactID(search, getToken())
            .then((res) => {

                const new_obj = res.data.data.results.map((item, index) => {
                    const obj = {
                        "id": index,
                        "contract_id": item.id,
                        "contract_no": item.properties.contract_no,
                        "name": item.properties.firstname + ' ' + item.properties.lastname,
                        "offer_limit": item.properties.offer_limit,
                        "conclude": item.properties.conclude,
                    }
                    return obj
                })

                setRows(new_obj)

                if (res.data.data.total === 0) {
                    alert('ค้นหาไม่พบ')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <div style={{ margin: '2rem' }}>
            <SideBar />
            <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
                <Grid item xs>
                    <Typography variant='h4' style={{ fontWeight: '' }}>File Management</Typography>
                </Grid>
            </Grid>

            <Paper elevation={3} >
                <Grid container padding={2} marginBottom={1}>
                    <Grid item xs>
                        <Typography variant='h6' >ค้นหาสัญญา</Typography>
                    </Grid>
                    <Grid item>
                        <TextField id="outlined-basic" label="เลขบัตรประชาชน" variant="outlined" size="small" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </Grid>
                    <Grid item paddingLeft={2}>
                        <Button variant="contained" onClick={() => handleSubmit()}>ค้นหา</Button>
                    </Grid>
                </Grid>
                <Box>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        autoHeight
                        sx={{
                            '& .MuiDataGrid-columnHeaderTitle': {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1.5,
                            },
                        }}
                    />
                </Box>
            </Paper>
        </div>
    )
}
